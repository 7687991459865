import { LIST_PERMISSION } from '@/constant/ListPermission';

const DEGREE_IDS = {
  ELEMENTARY_SCHOOL_I: 4,
  ELEMENTARY_SCHOOL_II: 1,
  HIGH_SCHOOL: 2,
  SAEB: 5,
  ENEM: 6,
};

export const PERMISSION_NAME_BY_DEGREE_ID = {
  [DEGREE_IDS.ELEMENTARY_SCHOOL_II]: LIST_PERMISSION.BOOKS_DEGREE_ELEMENTARY_SCHOOL_II,
  [DEGREE_IDS.HIGH_SCHOOL]: LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL,
  [DEGREE_IDS.SAEB]: LIST_PERMISSION.BOOKS_DEGREE_SAEB,
  [DEGREE_IDS.ENEM]: LIST_PERMISSION.BOOKS_DEGREE_ENEM,
};

export const DEGREE_ID_BY_PERMISSION_NAME = {
  [LIST_PERMISSION.BOOKS_DEGREE_ELEMENTARY_SCHOOL_II]: DEGREE_IDS.ELEMENTARY_SCHOOL_II,
  [LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL]: DEGREE_IDS.HIGH_SCHOOL,
  [LIST_PERMISSION.BOOKS_DEGREE_SAEB]: DEGREE_IDS.SAEB,
  [LIST_PERMISSION.BOOKS_DEGREE_ENEM]: DEGREE_IDS.ENEM,
};
